
import programSessionMixin from "@/mixins/programSession.mixin"

export default {
  mixins: [programSessionMixin],

  props: {
    activeGroup: { type: [Boolean, Object], default: () => {} },
    session: { type: Object, default: () => {} },
  },

  data: () => ({
    isOverflow: false,
    scrollHeight: 0,
  }),

  mounted () {
    if (this.activeGroup) {
      const { card } = this.$refs
      if (card) {
        const { offsetHeight, scrollHeight } = card.$el
        this.isOverflow = offsetHeight < scrollHeight
        this.scrollHeight = scrollHeight
      }
    }
  },

  methods: {
    onClick () {
      const { activeGroup, session } = this
      if (!activeGroup) return
      this.$nuxt.$emit("open-session", session)
    },
  },
}
