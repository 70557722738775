// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X5X9Ry49{display:none}@media(min-width:947.98px){.X5X9Ry49{align-items:center;display:flex;overflow:hidden;position:relative;width:var(--timeline-gap)}}.PbkhHQKn{height:150px;position:absolute;width:100%}.BYIbULKn{color:var(--color-gray-4);font-size:.81rem;font-weight:500;line-height:1.38}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"timeline": "X5X9Ry49",
	"timelineItem": "PbkhHQKn",
	"timelineLabel": "BYIbULKn"
};
module.exports = ___CSS_LOADER_EXPORT___;
