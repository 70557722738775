// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wFxbpS3i{flex-direction:column}.wFxbpS3i,.rXSQ0vpL{display:flex;flex-grow:1;position:relative}.rXSQ0vpL{padding:var(--spacing-sm) 0 0}@media(min-width:947.98px){.rXSQ0vpL:before{background-color:var(--color-gray-3);bottom:0;content:\"\";left:var(--timeline-gap);position:absolute;top:0;width:1px}}.\\+SpEr53K{display:flex;flex-grow:1}@media(min-width:947.98px){.\\+SpEr53K{margin:0 0 0 calc(var(--spacing-sm)/2)}}.AmfD6T-E{display:none;flex:1 1 0;position:relative;width:0}@media(min-width:947.98px){.AmfD6T-E{display:block}}.GBo7Kzre{display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"sessions": "wFxbpS3i",
	"sessionsCalendar": "rXSQ0vpL",
	"sessionsRow": "+SpEr53K",
	"sessionsColumn": "AmfD6T-E",
	"sessionsColumnActive": "GBo7Kzre"
};
module.exports = ___CSS_LOADER_EXPORT___;
