// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+s200oOv{border-bottom:1px solid var(--color-gray-3);display:flex;overflow-x:auto;padding-bottom:var(--spacing-lg)}@media(max-width:947.98px){.\\+s200oOv{border-bottom:none;padding-bottom:var(--spacing-sm)}}.\\+s200oOv>:not(:last-child){margin-right:var(--spacing-xxl)}@media(max-width:947.98px){.\\+s200oOv>:not(:last-child){margin-right:var(--spacing-sm)}}.\\+s200oOv a{font-size:.88rem;font-weight:600;letter-spacing:.75px;line-height:1;white-space:nowrap}@media(hover:hover){.\\+s200oOv a:hover{color:var(--color-primary-on-bg)}}@media(hover:none){.\\+s200oOv a:active{color:var(--color-primary-on-bg)}}.\\+s200oOv a.nuxt-link-active{color:var(--color-primary-on-bg);cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"groups": "+s200oOv"
};
module.exports = ___CSS_LOADER_EXPORT___;
