
import sessionMixin from "@/mixins/session"

export default {
  mixins: [sessionMixin],

  props: {
    isPage: { type: Boolean, default: false },
  },

  data: () => ({
    fullSession: null,
    participants: [],
  }),

  async fetch () {
    await this.fetchFullSession()
    await this.fetchParticipants()
  },

  computed: {
    speakers () {
      return this.participants.filter(p => p.speaker).map(p => p.person)
    },
    experts () {
      return this.participants.filter(p => p.expert).map(p => p.person)
    },
    moderators () {
      return this.participants.filter(p => p.moderator).map(p => p.person)
    },
    otherParticipants () {
      return this.participants
        .filter(p => !p.speaker && !p.expert && !p.moderator)
        .map(p => p.person)
    },
  },

  methods: {
    async fetchFullSession () {
      if (this.isPage) {
        this.fullSession = this.session
      } else {
        const [res, err] = await this.$api.program.getSession(this.session.alias)
        if (err) console.error(err)
        else this.fullSession = res
      }
    },

    async fetchParticipants () {
      const [res, err] = await this.$api.program.getParticipants({
        params: {
          "filter[session]": this.session.alias,
          "sort[lastName]": "asc",
        },
      })
      if (err) console.error(err)
      else this.participants = this.$api.helpers.getList(res)
    },

    closeModal () { this.$emit("close") },
  },
}
