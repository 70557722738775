// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o8-gAWts{font-size:1rem;line-height:1.4;margin-bottom:1rem}.RCIJDdAH{display:flex;justify-content:flex-end}.RCIJDdAH>*+*{margin-left:.5rem}.RCIJDdAH button{font-size:.75rem!important;padding:1em 2em!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"text": "o8-gAWts",
	"footer": "RCIJDdAH"
};
module.exports = ___CSS_LOADER_EXPORT___;
