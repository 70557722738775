// TODO: remove ?
import { mapActions, mapGetters } from "vuex"
import { getRange } from "@/utils/date"

export default {
  computed: {
    ...mapGetters("app", ["timezone"]),
    ...mapGetters("user", ["isFavorite"]),

    range () {
      const { session } = this
      return getRange(
        session.start, session.finish, this.$i18n.locale, this.timezone,
      )
    },
  },

  methods: {
    ...mapActions("user", {
      addFavoriteSession: "ADD_FAVORITE_SESSION",
      deleteFavoriteSession: "DELETE_FAVORITE_SESSION",
    }),

    async onFavoriteClick () {
      const { session } = this
      const { alias } = session
      if (this.isFavorite(alias)) {
        await this.deleteFavoriteSession(alias)
        this.$notify({
          message: this.$t("message.favorite.deleted"),
          type: "success",
        })
      } else {
        await this.addFavoriteSession(session)
        this.$notify({
          message: this.$t("message.favorite.added"),
          type: "success",
        })
      }
    },
  },
}
