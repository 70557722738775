// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ekrDcY35{display:flex;flex-shrink:0;overflow-x:auto;white-space:nowrap}@media(min-width:947.98px){.ekrDcY35{border-bottom:1px solid var(--color-gray-3);border-left:1px solid var(--color-gray-3);margin:0 0 0 var(--timeline-gap)}}.ekrDcY35 a{display:flex;flex-direction:column;font-size:1rem;font-weight:500;justify-content:center;line-height:1.62;min-height:3.75rem;white-space:nowrap}.ekrDcY35 a b{font-weight:inherit}.ekrDcY35 a i{color:var(--color-gray-3);font-size:.81rem;font-style:normal;line-height:1.38;margin-top:.15em}.ekrDcY35 a b,.ekrDcY35 a i{overflow:hidden;text-overflow:ellipsis}@media(max-width:947.98px){.ekrDcY35 a{border:1px solid var(--color-primary-on-bg);border-radius:var(--border-radius);max-width:40vw;padding:var(--spacing-xs)}}@media(max-width:947.98px)and (hover:hover){.ekrDcY35 a:hover{background:var(--color-primary-on-bg);border-color:var(--color-primary-on-bg);color:var(--color-bg)}.ekrDcY35 a:hover i{color:inherit}}@media(max-width:947.98px)and (hover:none){.ekrDcY35 a:active{background:var(--color-primary-on-bg);border-color:var(--color-primary-on-bg);color:var(--color-bg)}.ekrDcY35 a:active i{color:inherit}}@media(max-width:947.98px){.ekrDcY35 a:not(:last-child){margin-right:var(--spacing-xs)}}@media(max-width:947.98px){.ekrDcY35 a.nuxt-link-active{background:var(--color-primary-on-bg);border-color:var(--color-primary-on-bg);color:var(--color-bg);cursor:default}.ekrDcY35 a.nuxt-link-active i{color:inherit}}@media(min-width:947.98px){.ekrDcY35 a{color:var(--color-text)!important}.ekrDcY35 a{flex:1 1 0;height:4.38rem;padding:0 var(--spacing-sm);pointer-events:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"halls": "ekrDcY35"
};
module.exports = ___CSS_LOADER_EXPORT___;
