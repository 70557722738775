import { render, staticRenderFns } from "./ProgramSession.vue?vue&type=template&id=d5a0c932&"
import script from "./ProgramSession.vue?vue&type=script&lang=js&"
export * from "./ProgramSession.vue?vue&type=script&lang=js&"
import style0 from "./ProgramSession.vue?vue&type=style&index=0&id=d5a0c932&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppRange: require('/app/components/app/AppRange.vue').default,AppSessionFavoriteSwitch: require('/app/components/app/AppSessionFavoriteSwitch.vue').default})
