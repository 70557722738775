
import modalMixin from "@/mixins/modal"

export default modalMixin("open-session-registration").extend({
  data: () => ({
    sessionId: "",
  }),

  methods: {
    open (sessionId: string) {
      if (!sessionId)
        return

      this.sessionId = sessionId
      this.isOpen = true
    },

    register () {
      this.isOpen = false
      this.$nuxt.$emit("confirm-session-reg", this.sessionId)
    },
  },
})
