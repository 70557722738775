import { render, staticRenderFns } from "./ProgramSessions.vue?vue&type=template&id=92974bba&"
import script from "./ProgramSessions.vue?vue&type=script&lang=js&"
export * from "./ProgramSessions.vue?vue&type=script&lang=js&"
import style0 from "./ProgramSessions.vue?vue&type=style&index=0&id=92974bba&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProgramGroups: require('/app/components/program/ProgramGroups.vue').default,ProgramHalls: require('/app/components/program/ProgramHalls.vue').default,ProgramTimeline: require('/app/components/program/ProgramTimeline.vue').default,ProgramSession: require('/app/components/program/ProgramSession.vue').default,ProgramSessionModal: require('/app/components/program/ProgramSessionModal.vue').default,ProgramSpeakerModal: require('/app/components/program/ProgramSpeakerModal.vue').default,ProgramSessionRegisterModal: require('/app/components/program/ProgramSessionRegisterModal.vue').default})
