
import get from "lodash/get"

export default {
  props: {
    slug: { type: String, required: true },
    items: { type: Array, required: true },
    formatter: { type: Function, default: item => item.alias },
    doReset: { type: Boolean, default: false },
  },

  computed: {
    firstItemAlias () {
      const { items } = this
      return items.length ? items[0].alias : null
    },

    currentItemAlias () {
      return get(this.$route.query, this.slug, this.firstItemAlias)
    },
  },

  watch: {
    // remove query param from url if first tab selected
    $route (to) {
      if (!this.doReset) return
      const { query } = to
      const { slug } = this
      if (query[slug] !== this.firstItemAlias) return
      this.$router.push({
        query: { ...query, [slug]: undefined },
      })
    },
  },

  mounted () {
    // switch to first tab if alias doesn't exist
    const isAliasExist = this.items.some(i => i.alias === this.currentItemAlias)
    if (isAliasExist) return
    this.$router.push({
      query: { ...this.$route.query, [this.slug]: undefined },
    })
  },

  methods: {
    isCurrent (item) {
      return item.alias === this.currentItemAlias
    },
  },
}
